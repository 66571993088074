#seat-btn {

    margin-bottom: 2px !important;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.booked-seat {
    outline: none;
    background-color: #b2b2b2 !important;
    border: none !important;
    color: #ffff !important;
    font-weight: bold;
    border-radius: 6px !important;
    cursor: not-allowed !important;

}